import React, { useContext, useEffect } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import {
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'


const SidewaysNav = ({ pageid, lang, menu, pageContext, sidewaysLinkPrev, sidewaysLinkNext }) => {
  const dispatch = useContext(GlobalDispatchContext)
  let currentPageIndex = ''
  const getPageUri = ({ nextPage }) => {
    currentPageIndex = menu.findIndex(
      // x => x.path === pageContext.pagePath,
      x => x.path === pageContext.pagePath || x.path === `/${pageContext.pagePath}`,
    )
    if (
      currentPageIndex < 0 ||
      (nextPage && currentPageIndex >= menu.length - 1) ||
      (!nextPage && currentPageIndex <= 0)
    )
      return false
    return menu[currentPageIndex + (nextPage ? 1 : -1)].path
  }

  const nextPage = getPageUri({ nextPage: true })
  let prevPage = getPageUri({ nextPage: false })
  const nextPageLabel = nextPage ? menu[currentPageIndex + 1].label : false
  let prevPageLabel = prevPage ? menu[currentPageIndex - 1].label : false

  useEffect(() => {
    if(nextPage || prevPage) {
      dispatch({ type: 'SWIPE_BODY_CLASS', value: true })
    } else {
      dispatch({ type: 'SWIPE_BODY_CLASS', value: false })
    }
  }, [nextPage, prevPage]) // eslint-disable-line react-hooks/exhaustive-deps  

  if(pageid === 'start') {
    prevPage = '/'
    prevPageLabel = lang === 'pl-PL' ? 'Strona główna' : lang === 'de-DE' ? 'Heim' : 'Home'
  }

  return (
    <React.Fragment>
      {prevPage && (
        <AniLink
          className="prev-page"
          to={prevPage}
          duration={pageid === 'start' ? 3 : 1.6}

          // top="entry"

          top={pageContext.siteSlug === 'brand' ? 'entry' : undefined}
          swipe={pageid === 'start' ? false : true}
          direction="right"
          entryOffset={400}
          state={{ prevPath: pageContext.siteSlug }}
        >
          <span ref={sidewaysLinkPrev}>
            <FontAwesomeIcon icon={faChevronLeft} />
            {pageContext.sitePath === "/sport/" && <FontAwesomeIcon icon={faChevronLeft} className={`arrow2`} />}
            <span className={`hoverLabel`}></span>
            <span className={`sideNavLabel`}>{prevPageLabel && prevPageLabel}</span>
          </span>
        </AniLink>
      )}
      {nextPage && (
        <AniLink
          className="next-page"
          to={nextPage}
          duration={1.6}

          // top="entry"
          
          top={pageContext.siteSlug === 'brand' ? 'entry' : undefined}
          swipe
          direction="left"
          entryOffset={400}
          state={{ prevPath: pageContext.siteSlug }}
        >
          <span ref={sidewaysLinkNext}>
            <span className={`hoverLabel`}></span>
            <span className={`sideNavLabel`}>{nextPageLabel && nextPageLabel}</span>
            <FontAwesomeIcon icon={faChevronRight} />
            {pageContext.sitePath === "/sport/" && <FontAwesomeIcon icon={faChevronRight} className={`arrow2`} />}            
          </span>
        </AniLink>
      )}
    </React.Fragment>
  )
}
export default SidewaysNav