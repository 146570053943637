export const shippingEurope = [
  {countryPL: "Albania", countryEN: "Albania", countryDE: "Albanien", time: 6, price: 610},
  {countryPL: "Austria", countryEN: "Austria", countryDE: "Österreich", time: 3, price: 110},
  {countryPL: "Belgia", countryEN: "Belgium", countryDE: "Belgien", time: 3, price: 115},
  {countryPL: "Białoruś", countryEN: "Belarus", countryDE: "Weißrussland", time: 8, price: 660},
  {countryPL: "Bośnia i Hercegowina", countryEN: "Bosnia and Herzegovina", countryDE: "Bosnien und Herzegowina", time: 7, price: 610},
  {countryPL: "Bułgaria", countryEN: "Bulgaria", countryDE: "Bulgarien", time: 5, price: 110},
  {countryPL: "Chorwacja", countryEN: "Croatia", countryDE: "Kroatien", time: 4, price: 110},
  {countryPL: "Czarnogóra", countryEN: "Montenegro", countryDE: "Montenegro", time: 5, price: 610},
  {countryPL: "Czechy", countryEN: "Czech Republic", countryDE: "Tschechien", time: 3, price: 110},
  {countryPL: "Dania", countryEN: "Denmark", countryDE: "Dänemark", time: 3, price: 115},
  {countryPL: "Estonia", countryEN: "Estonia", countryDE: "Estland", time: 4, price: 115},
  {countryPL: "Finlandia", countryEN: "Finland", countryDE: "Finnland", time: 5, price: 115},
  {countryPL: "Francja", countryEN: "France", countryDE: "Frankreich", time: 4, price: 110},
  {countryPL: "Grecja", countryEN: "Greece", countryDE: "Griechenland", time: 5, price: 115},
  {countryPL: "Hiszpania", countryEN: "Spain", countryDE: "Spanien", time: 4, price: 110},
  {countryPL: "Holandia", countryEN: "The Netherlands", countryDE: "Die Niederlande", time: 4, price: 110},
  {countryPL: "Irlandia", countryEN: "Ireland", countryDE: "Irland", time: 4, price: 110},
  {countryPL: "Islandia", countryEN: "Islandia", countryDE: "Islandia", time: 4, price: 680},
  {countryPL: "Kazachstan", countryEN: "Kazakhstan", countryDE: "Kasachstan", time: 7, price: 396},
  {countryPL: "Liechtenstein", countryEN: "Liechtenstein", countryDE: "Liechtenstein", time: 4, price: 480},
  {countryPL: "Litwa", countryEN: "Lithuania", countryDE: "Litauen", time: 3, price: 115},
  {countryPL: "Luksemburg", countryEN: "Luxembourg", countryDE: "Luxemburg", time: 3, price: 115},
  {countryPL: "Łotwa", countryEN: "Latvia", countryDE: "Lettland", time: 3, price: 115},
  {countryPL: "Macedonia", countryEN: "Macedonia", countryDE: "Mazedonien", time: 5, price: 610},
  {countryPL: "Malta", countryEN: "Malta", countryDE: "Malta", time: 7, price: 650},
  {countryPL: "Mołdawia", countryEN: "Moldova", countryDE: "Moldawien", time: 7, price: 610},
  {countryPL: "Monako", countryEN: "Monaco", countryDE: "Monaco", time: 4, price: 115},
  {countryPL: "Niemcy", countryEN: "Germany", countryDE: "Deutschland", time: 4, price: 110},
  {countryPL: "Norwegia", countryEN: "Norway", countryDE: "Norwegen", time: 5, price: 310},
  {countryPL: "Polska", countryEN: "Poland", countryDE: "Polen", time: 1, price: 30},
  {countryPL: "Portugalia", countryEN: "Portugal", countryDE: "Portugal", time: 5, price: 115},
  {countryPL: "Rosja", countryEN: "Russia", countryDE: "Russland", time: 5, price: 620},
  {countryPL: "Rumunia", countryEN: "Romania", countryDE: "Rumänien", time: 4, price: 120},
  {countryPL: "San Marino", countryEN: "San Marino", countryDE: "San Marino", time: 5, price: 595},
  {countryPL: "Serbia", countryEN: "Serbia", countryDE: "Serbien", time: 5, price: 620},
  {countryPL: "Słowacja", countryEN: "Slovakia", countryDE: "Slowakei", time: 3, price: 115},
  {countryPL: "Słowenia", countryEN: "Slovenia", countryDE: "Slowenien", time: 3, price: 115},
  {countryPL: "Szwajcaria", countryEN: "Switzerland", countryDE: "Schweiz", time: 4, price: 310},
  {countryPL: "Szwecja", countryEN: "Sweden", countryDE: "Schweden", time: 4, price: 115},
  {countryPL: "Turcja", countryEN: "Turkey", countryDE: "Truthahn", time: 5, price: 690},
  {countryPL: "Ukraina", countryEN: "Ukraine", countryDE: "Ukraine", time: 6, price: 299},
  {countryPL: "Węgry", countryEN: "Hungary", countryDE: "Ungarn", time: 3, price: 115},
  {countryPL: "Wielka Brytania", countryEN: "United Kingdom", countryDE: "Vereinigtes Königreich", time: 4, price: 110},
  {countryPL: "Włochy", countryEN: "Italy", countryDE: "Italien", time: 4, price: 110},  
]