import React, { useState } from 'react'
import { Link } from 'gatsby'

import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'

export const makeNestedMenu = items => {
  const menu = []
  items.forEach(item => {
    if (!item.parentId) {
      menu.push(item)
      return
    }

    const parentIndex = menu.findIndex(v => v.id === item.parentId)
    if (
      menu[parentIndex].children &&
      !menu[parentIndex].children.find(v => v.id === item.id)
    )
      menu[parentIndex].children.push(item)
    else menu[parentIndex].children = [item]
  })

  return menu
}

const SubmenuList = ({ pageContext, list, sublist, menu, item, useAniLink }) => {
  const currentPageIndex = list.findIndex(x => x.path === pageContext.pagePath) + 1
  const [itemOpen, setItemOpen] = useState(
    item.path === pageContext.pagePath || sublist.findIndex(x => x.path === pageContext.pagePath) >= 0
  )

  return (
    <>
      {item.path === '##' ? (
        <button
          className={`subMenuButton ${itemOpen ? 'subMenuButtonOpen' : 'subMenuButtonClosed'}`}
          style={{
            cursor: 'pointer',
            lineHeight: 'unset',
            boxSizing: 'border-box'
          }}
          onClick={() => {setItemOpen(!itemOpen)}}
          onKeyDown={() => {setItemOpen(!itemOpen)}}
          tabIndex="0"
        >
          <span>{item.label}</span>
          <FontAwesomeIcon
            className="menu_button_top-arrow"
            icon={itemOpen ? faChevronDown : faChevronUp}
          />
        </button>
      ) : ''}
      <ul className="subLevel" style={{ height: itemOpen ? `${sublist.length * 43}px` : '0px' }}>
        {sublist.map(item => {
          // const itemIndex = list.findIndex(x => x.path === item.path)

          return (
            <li key={item.id} className={`subLevelLink menuLink ${item.path === pageContext.pagePath ? 'nav-bar_link--active' : ''}`}>
              {useAniLink ? (
                <AniLink
                  to={item.path}
                  duration={1.6}                  

                  // top="entry"

                  top={pageContext.siteSlug === 'brand' ? 'entry' : undefined}
                  className={item.path === pageContext.pagePath ? 'nav-bar_link--active' : ''}
                  swipe
                  direction={item.order > currentPageIndex ? 'left' : 'right'}
                  entryOffset={400}
                  state={{ prevPath: pageContext.siteSlug }}
                >
                  <span>{item.label}</span>
                </AniLink>
              ) : (
                <Link
                  to={item.path}
                  activeClassName="nav-bar_link--active"
                  itemProp="url"
                  state={{ prevPath: pageContext.siteSlug }}
                >
                  <span>{item.label}</span>
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}

const MenuList = ({ pageContext, list, useAniLink, site }) => {
  const menu = makeNestedMenu(list)
  const currentPageIndex = list.findIndex(x => x.path === pageContext.pagePath) + 1

  return (
    <ul className={`topLevel`}>
      {menu.map((item, index) => {
        // console.log(item)
        return (
          <React.Fragment 
            // key={item.id} 
            key={index} 
          >
          {!item.cssClasses.includes('hidden') && <li 
            // key={item.id} 
            className={`topLevelLink menuLink ${item.path === pageContext.pagePath ? 'nav-bar_link--active' : ''} ${item.children ? 'itemHaveChildren' : ''}`}
          >
            {item.path !== '##' ?
              useAniLink ? (
                <AniLink
                  to={item.path === '#' ? item.children[0].path : item.path}
                  duration={1.6}

                  top="entry"

                  className={item.path === pageContext.pagePath ? 'nav-bar_link--active' : ''}
                  swipe
                  direction={currentPageIndex === 0 ? 'up' : item.order > currentPageIndex ? 'left' : 'right'}
                  entryOffset={400}
                  state={{ prevPath: pageContext.siteSlug }}
                >
                  <span>{item.label}</span>
                </AniLink>
              ) : (
                <Link
                  to={item.path}
                  activeClassName="nav-bar_link--active"
                  itemProp="url"
                  state={{ prevPath: pageContext.siteSlug }}
                >
                  <span>{item.label}</span>
                </Link>
              )
            : ''}
            {item.children ?
              <SubmenuList
                pageContext={pageContext}
                list={list}
                sublist={item.children}
                item={item}
                useAniLink={useAniLink}
              />
            : ''}
          </li>}
          </ React.Fragment>
        )
      })}
    </ul>
  )
}
export default MenuList