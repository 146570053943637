import React from 'react'
// import React, { useContext } from "react"
// import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"

function ShippingSvgDefSet() {
  // const dispatch = useContext(GlobalDispatchContext)
  // const stateCtx = useContext(GlobalStateContext)

  return (
    <>

      <filter id="svgShadow" x={`-100%`} y={`-100%`} width={`300%`} height={`300%`} style={{width:'0',height:'0'}}>
        <feMorphology operator="erode" radius="2" in="SourceAlpha" result="THICKNESS" />
        <feGaussianBlur in="THICKNESS" stdDeviation="10" result="blur" />
        <feOffset dx="-1" dy="5" in="blur" result="offsetblur"/>
        <feComponentTransfer>
          <feFuncA type="linear" slope="1.05"/>
        </feComponentTransfer>
        <feMerge result="merg"> 
          <feMergeNode/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>

        {/* <feComposite operator="over" in2="xxxxxoffsetblur" in="xxxxxSourceGraphic"></feComposite> */}

      </filter>

      <radialGradient 
        id="activeRegion" 
        cx="975" 
        cy="342" 
        r="1000"
        fx="500" 
        gradientUnits="userSpaceOnUse" 
        spreadMethod="repeat"
      >
        <stop offset="0" stopColor="#ff4a4a" stopOpacity="1"/>
        <stop offset="1" stopColor="#990000" stopOpacity="1"/>
      </radialGradient>

    </>
  )
}
export default ShippingSvgDefSet