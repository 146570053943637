import React from 'react'
// import React, { useContext } from "react"
// import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
// import { GlobalStateContext } from "../context/GlobalContextProvider"

function SvgPlay() {
  // const dispatch = useContext(GlobalDispatchContext)
  // const stateCtx = useContext(GlobalStateContext)

  return (
    <>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.27 27.58">
        
        <path d="M26.18,15a1.38,1.38,0,0,0-.63-1.16L7.05,1.44a1.3,1.3,0,0,0-.75-.23A1.38,1.38,0,0,0,4.92,2.59h0V27.41A1.37,1.37,0,0,0,7,28.57H7L25.51,16.18A1.38,1.38,0,0,0,26.18,15Z" transform="translate(-4.92 -1.21)" />
        
      </svg>

    </>
  )
}
export default SvgPlay