import React, { useContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import MenuList from 'src/components/common/menuList'
import SidewaysNav from 'src/components/common/sidewaysNav'
import LangSwitchUnit from 'src/components/common/langSwitchUnitSIMPLE'
import LangSwitchUnitMobile from 'src/components/common/langSwitchUnitSIMPLEmobile'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

import 'src/styles/global.scss'


const Header = ({ pageContext, pageid, headerMenu, switchMenu, sidewaysLinkPrev, sidewaysLinkNext }) => {
  const graphqlResult = useStaticQuery(graphql`
    query MenuListQuery {
      wp {
        allSettings {
          generalSettingsUrl
        }
      }
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
          line2GenralInfosInfo
        }
      }
    }
  `)
  const prefix = `/${graphqlResult.wp.allSettings.generalSettingsUrl.split('/').pop()}`
  headerMenu.edges[0].node.menuItems.nodes = headerMenu.edges[0].node.menuItems.nodes.map(x => ({
    ...x,
    path: x.path.replace(prefix, '')
  }))

  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const switchLinks = switchMenu.edges[0].node.menuItems.nodes
  const switchLinksFiltred = pageContext.sitePath !== '' ? switchLinks.filter((item) => {
    let xx = item.path.search(`/${pageContext.realSlugs[pageContext.siteSlug]}/`)
    return xx === -1 || item.path.startsWith('http')
  }) : switchLinks.filter((item) => {
    let xx = item.path.search(`/${pageContext.realSlugs[pageContext.siteSlug]}/`)
    return xx === -1 || item.path.startsWith('http')
  })

  headerMenu.edges[0].node.menuItems.nodes[0].path = `/${pageContext.realSlugs[pageContext.siteSlug]}/`

  
  const toggleMenu = (e) => {
    dispatch({ type: 'TOGGLE_MENU' })
  }

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset;
      if (currentPosition > scrollTop) {
        // downscroll code

        // setScrolling(false);
      } else {
        // upscroll code

        // setScrolling(true);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  let scrollCompensation = scrollTop <= 31 ? 31 - scrollTop : 0


  return (
    <>

    <div className="lang-switch mobile" style={{fontWeight: '300',lineHeight:'32px'}}>
      <div className='langTitle'>
        <span>{graphqlResult.siteLanguage.acfgeneralinfos_layout.line2GenralInfosInfo}:</span>
      </div>
      <div className="lang-switchIN">
        <LangSwitchUnitMobile site={``} />
      </div>
    </div>

    <header className={`header ${state.menu ? 'header--open' : ''}`}>
      <div className="nav-bar">
        <span
          className="burger"
          style={state.menu ? {position:'fixed', top: '33px'} : state.windowWidth <= 750 ? {
            top: `${scrollCompensation}px`
          } : {top: '33px'}}
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
          tabIndex="0"
          role="button"
        >
          {!state.menu ?
            <div style={{
              width:'50px',
              height:'94px',
              paddingLeft:'20px',
              paddingRight:'5px',
              boxSizing:'border-box',
              position:'absolute',
              zIndex:'1001',
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
            }}>
              <div style={{height:'5px',backgroundColor:`${pageContext.siteSlug === 'business' ? '#D4AB4A' : '#f3cf45'}`,borderRadius:'2px'}}></div>
              <div style={{height:'5px',backgroundColor:'#000',borderRadius:'2px'}}></div>
              <div style={{height:'5px',backgroundColor:`${pageContext.siteSlug === 'business' ? '#D4AB4A' : '#f3cf45'}`,borderRadius:'2px'}}></div>
              <div style={{height:'5px',backgroundColor:'#000',borderRadius:'2px'}}></div>
              <div style={{height:'5px',backgroundColor:`${pageContext.siteSlug === 'business' ? '#D4AB4A' : '#f3cf45'}`,borderRadius:'2px'}}></div>
              <div style={{height:'5px',backgroundColor:'#000',borderRadius:'2px'}}></div>
            </div>
            :
            <FontAwesomeIcon
              icon={faTimes}
            />
          }

        </span>

        <nav style={state.menu ? { flexBasis: '100%' } : {}}>

          <MenuList page={pageContext.pageSlug} pageContext={pageContext} site={pageContext.siteSlug} list={headerMenu.edges[0].node.menuItems.nodes} useAniLink />
          <span className="separate"></span>

          <ul className={`switchNav`}>
            {switchLinksFiltred && switchLinksFiltred.map(item => {
              const linkTo = item.path.slice(0, item.path.slice(1).indexOf('/') + 1)
              return(
                  <React.Fragment 
                    key={item.id}
                  >
                    {item.cssClasses.length === 0 ?
                      <li 
                        className={`menuLink`}
                      >
                        <AniLink
                          to={linkTo}
                          duration={1.6}

                          swipe
                          entryOffset={400}
                          direction="right"
                        >
                          {item.label}
                        </AniLink>
                      </li> 
                    :
                      <li 
                        key={item.id} 
                        className={item.cssClasses[0]}
                      >
                        <a                            
                          // target="_blank"
                          // rel="noreferrer"
                          href={item.path}
                          className={`menuLink`}
                        >
                          {item.label}
                        </a>
                      </li>
                    }
                  </React.Fragment>
                )
            })}
          </ul>

        </nav>
      </div>

      <SidewaysNav pageid={pageid} lang={graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo} menu={headerMenu.edges[0].node.menuItems.nodes.filter(x => x.path !== '#' && x.path !== '##')} pageContext={pageContext} sidewaysLinkPrev={sidewaysLinkPrev} sidewaysLinkNext={sidewaysLinkNext} />

      {state.menu && 
        <div 
          style={{
            minHeight:'100vh',
            minWidth:'100vw',
            position:'fixed',
            top:'0',
            left:'0',
            cursor: 'pointer'
          }} 
          className={`navClose`} 
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
          tabIndex="0"
          role="button"
        >
        </div>
      }
    </header>
  </>
  )
}
export default Header