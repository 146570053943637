import React, { Component } from 'react'
import ReactDOM from 'react-dom'

// const portalRoot = typeof document !== `undefined` ? document.body : false
const portalRoot = typeof document !== `undefined` ? document.getElementById("bodyTop") : false

const PortalContainer = props => {
	const zIndex = (function(level) {
		if (!isNaN(level)) return level		
		switch (level) {
			case 'bottom':
				return 0
			case 'top':
				return 10000
			case 'uberTop':
				return 100000
			default:
				return 1000
		}
	})(props.level)

	const position = (function(position) {
		if (!isNaN(position)) return position		
		switch (position) {
			case 'relative':
				return 'absolute'
			default:
				return 'fixed'
		}
	})(props.position)

	return (
		<div
			className="layout-portal"
			style={{
				position: position,
				top: 0,
				left: 0,
				zIndex: zIndex,
				// overflowX: 'hidden',
				// overflowY: 'scroll',
			}}>
			{props.children}
		</div>
	)
}

export default class TransitionPortal extends Component {
	constructor() {
		super()
		this.el =
			typeof document !== `undefined`
				? document.createElement('div')
				: false
	}

	componentDidMount = () => {
		portalRoot && portalRoot.appendChild(this.el)
	}

	componentWillUnmount = () => {
		portalRoot && portalRoot.removeChild(this.el)
	}

	render() {
		return this.el && portalRoot
			? ReactDOM.createPortal(
					<>
						{portalRoot && (
							<PortalContainer
								styles={this.props.css}
								position={this.props.position}
								level={this.props.level}>
								{this.props.children}
							</PortalContainer>
						)}
					</>,
					this.el
			  )
			: null
	}
}